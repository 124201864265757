.floating {
  position: absolute;
  right: 20px;
  transition: top 0.2s ease-out, opacity 0.3s ease-out;
  z-index: 60;
  background-color: rgba(19, 19, 19, 0.4);
  padding: 12px;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  box-shadow: 0 0 2px var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.floating.hidden {
  opacity: 0;
  pointer-events: none;
}

.floating-mobile {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #131313;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 30px;
  box-shadow: 0 0 1px var(--primary) inset;

  .m-floating-text {
    font-size: 12px;
    font-weight: 600;
    color: var(--primary);
  }

  .floating-scroll {
    width: 20px;
  }
}

.floating-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
}

.floating-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .floating-input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .floating-label {
      font-size: 14px;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      text-align: start;
      opacity: 0.6;
    }

    .floating-input {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      font-size: 14px;
      font-weight: 400;
      font-family: Pretendard, sans-serif;
      padding: 0 8px;
      box-sizing: border-box;
      border: none;
      border-radius: 8px;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 1px var(--primary) inset;
      }
    }
  }

  .text {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;

    .floating-checkbox {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .floating-a {
      color: var(--primary);
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .floating-button {
    width: 100%;
    min-width: 188px;
    height: 36px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: var(--primary);
    color: black;

    &:disabled {
      opacity: .4;
    }
  }
}

.gender {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .gender-option {
    width: 50%;
    text-align: center;
    font-size: 12px;
    padding: 8px 0;
    color: #131313;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 160, 0, .3);
    }
  }

  .gender-option.selected {
    background-color: var(--primary);
    font-weight: bold;
    border-radius: 8px;
  }
}