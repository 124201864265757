.footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, .06);

  .footer-container {
    width: 1200px;
    max-width: 100%;
    padding: 48px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;

    @media(max-width: 768px) {
      flex-direction: column;
      gap: 40px;
      padding: 32px 24px;
    }

    .company {
      font-size: 16px;
      font-weight: 600;
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      .info-row {
        font-size: 12px;
      }
    }
  }
}