.download-btn {
  padding: 12px 28px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  font-size: 16px;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  @media(max-width: 768px) {
    padding: 12px 20px;
    font-size: 16px;
  }

  @media(max-width: 480px) {
    padding: 10px 20px;
    font-size: 14px;
  }

  .download-btn-img {
    width: 24px;

    @media(max-width: 768px) {
      width: 16px;
    }
  }
}