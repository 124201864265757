@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.modal-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 80;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 32px;
    box-sizing: border-box;
    background: #010101;
    border-radius: 20px;


    @media (max-width: 768px) {
      padding: 20px;
      width: calc(100vw - 40px);
      max-width: 300px;
    }

    .modal-title {
      font-size: 20px;
      font-weight: 600;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .modal-desc {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      opacity: .6;
      text-align: start;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .policy {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      padding: 12px 20px;
      background: rgba(255, 255, 255, .08);
      border-radius: 8px;

      .policy-num {
        font-size: 20px;
        font-family: Gmarket Sans TTFm sans-serif;
        font-weight: 700;
        color: var(--primary);
      }

      .policy-text {
        font-size: 12px;
        line-height: 1.4;
      }
    }

    .modal-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 12px;

      .cancel {
        width: 50%;
        height: 40px;
        background: rgba(255, 255, 255, .1);
        border-radius: 8px;
        text-align: center;
        align-content: center;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }

      .confirm {
        width: 50%;
        height: 40px;
        background: var(--primary);
        color: black;
        border-radius: 8px;
        text-align: center;
        align-content: center;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }

      .hover {
        &:hover {
          opacity: .8;
        }
      }
    }

    .confirm-desc {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      text-align: start;
    }
  }
}