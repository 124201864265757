@keyframes scroll-tags {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-400px);
  }
}

.body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .background {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .another-class-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 30;
    gap: 120px;
    box-sizing: border-box;
    padding: 280px 0;

    .first {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 120px;

      .title-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        opacity: .8;
      }

      .download-buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .second {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .second-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .third {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      max-width: 1000px;
      padding: 0 40px;
      box-sizing: border-box;

      .third-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 12px;

        .diff-box {
          width: 32%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 20px;
          border: 1px solid var(--primary);
          border-radius: 32px;
          gap: 20px;

          .diff-box-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
          }

          .diff-box-btn {
            min-width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            height: 36px;
            background: var(--primary);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 16px;
            border-radius: 18px;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;

            .diff-box-icon {
              width: 24px;
            }
          }
        }

        .diff-box.text {
          justify-content: flex-start;
          gap: 12px;
        }

        .diff-box-linear {
          background: linear-gradient(180deg, rgba(245, 196, 59, 0.15) 0%, rgba(185, 177, 120, 0.15) 100%);
        }
      }
    }

    .fourth {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-bottom: 100px;

      .fourth-background {
        width: 100%;
        height: 360px;
        position: absolute;
        background: #222222;
        z-index: 0;
        bottom: 0;
      }

      .fourth-content {
        width: 100%;
        max-width: 600px;
        z-index: 30;
      }

      .fourth-mask {
        width: 220px;
        height: 468px;
        border-radius: 27px;
        position: absolute;
        transform: translateX(-50%);
        left: calc(50% - 152px);
        top: 24px;
        z-index: 40;
        overflow: hidden;

        .fourth-tags {
          width: 216px;
          position: absolute;
          left: 0;
          animation: scroll-tags 10s linear infinite; /* 무한 스크롤 애니메이션 */
        }
      }
    }

    @keyframes scroll {
      0% {
        transform: translateY(0); /* 초기 위치 */
      }
      100% {
        transform: translateY(-700px); /* 위로 스크롤 */
      }
    }

    .sixth {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;

      .six-content-box {
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 48px;
        background: #2C2C2C;
        position: relative;
        gap: 80px;
        margin-bottom: 24px;
        overflow: hidden;


        .six-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 40px;
        }

        .six-content-img {
          width: 200px;
        }

        .six-content-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }

        .arrow {
          width: 20px;
          cursor: pointer;
        }

        .sixth-indicator {
          position: absolute;
          bottom: 28px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
      }

      .sixth-content-box-2 {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .sixth-content-2 {
          width: 100px;
          height: 100px;
          border-radius: 60px;
          border: 1px solid var(--primary);
          align-content: center;
          font-size: 14px;
          font-weight: 600;
          color: var(--primary);
          text-align: center;
        }
      }
    }

    .seventh {
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;
      box-sizing: border-box;
      padding: 40px;

      .seventh-content-box-1 {
        width: 100%;
        box-sizing: border-box;
        padding: 80px 20px;
        max-width: 900px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .seventh-content-1-1 {
          width: 120px;
          height: 120px;
          border: 1px solid var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60px;
          margin-right: 40px;
          color: var(--primary);
        }

        .seventh-content-1-2 {
          display: flex;
          flex-direction: column;
          flex: 1;
          z-index: 10;
          gap: 60px;

          .info-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 48px;
            box-sizing: border-box;
            padding: 0 40px;
            height: 60px;
            border-radius: 30px;
            background: #282828;

            .info-row-img {
              width: 40%;
            }

            @media (max-width: 900px) {
              padding: 0 20px;

              .info-row-img {
                width: 44%;
              }
            }
          }
        }

        .planner {
          height: 268px;
          margin-left: -20px;
          z-index: 0;
          margin-bottom: 20px;
        }
      }

      .seventh-content-box {
        width: 100vw;
        padding: 60px;
        background: #121212;
        display: flex;
        align-items: center;
        justify-content: center;

        .seventh-content-2 {
          width: 100%;
          max-width: 600px;
        }
      }
    }

    .eighth {
      box-sizing: border-box;
      width: 100%;
      max-width: 900px;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      gap: 24px;

      .eighth-content {
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .eighth-text {
          max-width: 200px;
          text-align: center;
        }

        .eighth-img {
          width: 100%;
          max-width: 280px;
          border-radius: 12px;
        }
      }
    }

    .ninth {
      box-sizing: border-box;
      width: 100%;
      max-width: 1000px;
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;

      .review {
        width: 100%;
      }
    }


    .bottomRef {
      width: 100%;
      height: 20px;
      max-height: 20px;
      min-height: 20px;
    }

    .tenth {
      width: 600px;
      min-width: 600px;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .tenth-title {
        background: linear-gradient(90deg, #FFF 0%, #999 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        white-space: nowrap;
      }

      .tenth-content {
        width: 540px;
        min-width: 540px;
        max-width: 540px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 40px 0;

        .landing-gender {
          width: 100%;
          background: rgba(255, 255, 255, 1);
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .landing-gender-option {
            width: 50%;
            text-align: center;
            font-size: 16px;
            padding: 10px 0;
            color: #131313;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              background-color: rgba(255, 160, 0, .3);
            }
          }

          .landing-gender-option.selected {
            background-color: var(--primary);
            font-weight: bold;
            border-radius: 8px;
          }
        }

        .input-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .input-label {
            width: 60px;
            text-align: end;
          }

          .input {
            flex: 1;
            font-size: 18px;
            font-family: Pretendard, sans-serif;
            font-weight: 500;
            padding: 12px;
            border-radius: 8px;
            border: none;
            outline: none;
          }
        }

        .tenth-text-box {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;

          .warning-text {
            flex: 1;
            font-size: 14px;
            text-align: start;
            font-weight: 400;
            line-height: 140%;
            color: rgba(255, 255, 255, .4);
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            .checkbox {
              width: 20px;
              height: 20px;
              accent-color: var(--primary);
              cursor: pointer;
            }

            .a {
              text-decoration: underline;
              margin-right: -4px;
              cursor: pointer;

              &:hover {
                color: var(--primary);
              }

            }
          }

          .submit {
            padding: 8px 40px;
            border: 1px solid;
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;

            &:disabled {
              background: rgba(255, 255, 255, 0.6);
              cursor: default;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }

    .download {
      width: 100%;
      max-width: 560px;
      min-width: 480px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      position: relative;

      .download-logo {
        width: 280px;
      }

      .text-28 {
        font-size: 28px;
        text-align: start;
      }

      .download-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;

        .download-button {
          width: 50%;
          height: 56px;
          background: rgba(255, 255, 255, .1);
          padding: 0 16px;
          box-sizing: border-box;
          align-content: center;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .download-img {
            width: 140px;
          }
        }
      }

      .qr-code {
        width: 120px;
        position: absolute;
        bottom: 72px;
        right: 0;
      }
    }
  }
}

.body::-webkit-scrollbar {
  display: none;
}


.text-14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.text-20 {
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.text-24 {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.text-28 {
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
}

.text-32 {
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
}

.text-36 {
  font-size: 36px;
  font-weight: 700;
  line-height: 140%;
}

.title-1 {
  font-size: 2.4vw;
  font-weight: 700;
}

.title-2 {
  font-size: 2.8vw;
  font-weight: 700;
}

.title-3 {
  font-size: 4.4vw;
  font-weight: 800;
  margin-top: 12px;
}

.glowing {
  text-shadow: 0px 0px 6.8px rgba(255, 255, 255, 0.50);
}

.opacity-60 {
  color: rgba(255, 255, 255, 0.6);
  text-align: start;
}

.bold {
  font-weight: 700;
  color: #EDEDED;
}
