.matching {
  width: 100vw;
  background: rgba(255, 255, 255, .08);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px;

  .matching-box {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .matching-box-left {
      width: 180px;
      height: 240px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .left-first {
        height: 45%;
        border-radius: 4px;
        border: 1px solid #F1582C;
        background: rgba(241, 88, 44, 0.25);
        box-shadow: 0px 0px 24px 0px rgba(241, 88, 44, 0.3) inset;
        text-align: center;
        align-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #F1582C;
      }

      .left-second {
        height: 35%;
        border-radius: 4px;
        border: 1px solid #FF8B02;
        background: rgba(255, 139, 2, 0.30);
        box-shadow: 0px 0px 20px 0px rgba(255, 139, 2, 0.4) inset;
        text-align: center;
        align-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #FF8B02;
      }

      .left-third {
        height: 10%;
        border-radius: 4px;
        border: 1px solid rgba(225, 214, 162, .6);
        background: rgba(225, 214, 162, 0.20);
        box-shadow: 0px 0px 53.2px 0px rgba(225, 214, 162, 0.25) inset;
        text-align: center;
        align-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #E1D6A2;
      }
    }

    .matching-box-line-1 {
      width: 80px;
      height: 2px;
      background: #FFA000;
      filter: drop-shadow(0px 0px 6px #FFA000);
    }

    .matching-box-line-2 {
      width: 80px;
      height: 2px;
      filter: drop-shadow(0px 0px 6px #FFA000);
      background-image: linear-gradient(to right, #FFA000 50%,
      rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 12px 2px;
      background-repeat: repeat-x;
    }

    .matching-box-right {
      width: 220px;
      height: 220px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .right-first {
        width: 220px;
        height: 220px;
        border: 1px solid #FFA000;
        border-radius: 110px;
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        top: 0;
        filter: drop-shadow(0px 0px 6px #FFA000) blur(1px);
        opacity: .2;
      }

      .right-second {
        width: 196px;
        height: 196px;
        border: 1px solid #FFA000;
        border-radius: 110px;
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        top: 12px;
        filter: drop-shadow(0px 0px 12px #FFA000);
        opacity: .7;
      }

      .right-third {
        width: 148px;
        height: 148px;
        border: 2px solid #FFA000;
        border-radius: 110px;
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        top: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: #FFA000;
      }
    }
  }
}

@media(max-width: 768px) {
  .matching {
    width: 100vw;
    background: rgba(255, 255, 255, .08);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 40px 20px;

    .matching-box {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .matching-box-left {
        width: 40%;
        max-width: 280px;
        height: 180px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .left-first {
          height: 45%;
          border-radius: 4px;
          border: 1px solid #E6537E;
          background: rgba(230, 83, 126, 0.30);
          box-shadow: 0px 0px 24px 0px rgba(230, 83, 126, 0.30) inset;
          text-align: center;
          align-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #FAF9F6;
        }

        .left-second {
          height: 35%;
          border-radius: 4px;
          border: 1px solid #CD3C93;
          background: rgba(255, 0, 153, 0.30);
          box-shadow: 0px 0px 35.5px 0px rgba(230, 83, 126, 0.32) inset;
          text-align: center;
          align-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #DDDDDD;
        }

        .left-third {
          height: 15%;
          border-radius: 4px;
          border: 1px solid rgba(225, 214, 162, .6);
          background: rgba(225, 214, 162, 0.20);
          box-shadow: 0px 0px 53.2px 0px rgba(225, 214, 162, 0.25) inset;
          text-align: center;
          align-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #E1D6A2;
        }
      }

      .matching-box-line-1 {
        width: 28px;
        height: 2px;
        background: #E6537E;
        filter: drop-shadow(0px 0px 6px #E6537E);
      }

      .matching-box-line-2 {
        width: 28px;
        height: 2px;
        filter: drop-shadow(0px 0px 6px #E6537E);
        background-image: linear-gradient(to right, #E6537E 50%,
        rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 12px 2px;
        background-repeat: repeat-x;
      }

      .matching-box-right {
        width: 184px;
        height: 184px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -20px;

        .right-first {
          width: 180px;
          height: 180px;
          border: 2px solid #E6537E;
          border-radius: 90px;
          position: absolute;
          transform: translate(-50%);
          left: 50%;
          top: 0;
          filter: drop-shadow(0px 0px 6px #E6537E) blur(1px);
          opacity: .2;
        }

        .right-second {
          width: 148px;
          height: 148px;
          border: 1.5px solid #E6537E;
          border-radius: 74px;
          position: absolute;
          transform: translate(-50%);
          left: 50%;
          top: 16px;
          filter: drop-shadow(0px 0px 12px #E6537E);
          opacity: .7;
        }

        .right-third {
          width: 120px;
          height: 120px;
          border: 2px solid #E6537E;
          border-radius: 60px;
          position: absolute;
          transform: translate(-50%);
          left: 50%;
          top: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 600;
          color: #FAAFC5;
        }
      }
    }
  }
}