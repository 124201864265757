@keyframes screen-scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-480px);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

.m-body {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #070909;

  .m-background {
    width: 100%;
    position: absolute;
    left: 0;
    top: 96px;
    z-index: 0;
  }

  .m-another-class-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 30;
    gap: 120px;
    box-sizing: border-box;
    padding: 120px 20px;

    .m-first {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 120px;

      .m-title-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        opacity: .8;
      }

      .download-buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .m-second {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin-top: -60px;

      .m-second-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
    }

    .m-third {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      box-sizing: border-box;
      overflow-x: hidden;

      .m-third-2 {
        width: 100%;
        text-align: center;
      }
    }

    .m-fourth {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .m-fourth-bg {
        width: 360px;
        max-width: 360px;
        min-width: 360px;
      }

      .m-fourth-icon {
        width: 40px;
      }

      .m-fourth-mask {
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        height: 322px;
        position: absolute;
        top: 176px;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;

        .m-fourth-tag-screen {
          width: 240px;
          position: absolute;
          left: 0;
          animation: screen-scroll 8s linear infinite;
        }
      }
    }

    .m-fifth {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .m-fifth-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;

        .m-fifth-content-top {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .m-fifth-content-bottom {
          background: linear-gradient(115deg, #757C86 23.61%, #41454B 76.47%);
          padding: 20px;
          border-radius: 12px;
          font-size: 16px;
          line-height: 160%;
          text-align: center;
          color: rgba(255, 255, 255, .6);
        }

        .m-fifth-content-box {
          box-sizing: border-box;
          width: 100%;
          max-width: 100%;
          aspect-ratio: 5/3;
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          gap: 12px;
          border-radius: 12px;
        }

        .m-image-1 {
          background-image: url('../assets/m-bg-image-1.png');
          background-size: cover;
        }

        .m-image-2 {
          background-image: url('../assets/m-bg-image-2.png');
          background-size: cover;
        }

        .m-image-3 {
          background-image: url('../assets/m-bg-image-3.png');
          background-size: cover;
        }
      }
    }

    .m-sixth {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .m-sixth-content-box {
        width: 100vw;
        box-sizing: border-box;
        padding: 40px 24px 80px 24px;
        background: linear-gradient(180deg, #292929 0%, #232323 100%);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        .m-figure {
          width: 120px;
          height: 20px;
          background: #131313;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 30px;
        }

        .m-chat-right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 12px;

          .m-chatbox-right {
            width: 64%;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.4;
            text-align: end;
            padding: 12px;
            background: #3F3F3F;
            border-radius: 12px 0 12px 12px;
          }
        }

        .m-chat-left-profile {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;

        }


        .m-chat-left {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }

        .m-chatbox-left {
          width: 88%;
          background: #EAEEF4;
          border-radius: 12px;
          box-sizing: border-box;
          padding: 16px;
          color: #363636;
          font-size: 12px;
          line-height: 1.4;
          text-align: start;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .user-profile {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;

            .user-profile-img {
              height: 88px;
              border-radius: 8px;
            }

            .user-profile-info-container {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;

              .user-profile-info {
                width: 100%;
                box-sizing: border-box;
                padding: 8px;
                background: #BD6E8E;
                color: white;
                font-size: 12px;
                font-weight: 400;
                border-radius: 8px;
              }

              .user-profile-info-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 8px;

                .user-profile-info-row-label {
                  min-width: 56px;
                  font-size: 12px;
                  font-weight: 600;
                  color: #E6537E;
                }

                .user-profile-info-value {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 1.4;
                }
              }
            }
          }
        }
      }

      .m-sixth-content-bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }

      .m-sixth-content-box-2 {
        width: 100%;
        max-width: 480px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;

        .m-sixth-bg {
          background: linear-gradient(148deg, #E6537E 5.08%, #B11140 90.69%);
          width: 102px;
          height: 102px;
          border-radius: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .m-sixth-content-2 {
          width: 100px;
          height: 100px;
          border-radius: 60px;
          align-content: center;
          background: black;
          font-size: 14px;
          font-weight: 600;
          color: #B7B7B7;
          text-align: center;
        }
      }
    }

    .m-seventh {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 100px;
      box-sizing: border-box;

      .m-seventh-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }

      .m-seventh-content-1 {
        width: 100%;
      }

      .m-seventh-content-box {
        width: 100vw;
        padding: 40px 20px;
        box-sizing: border-box;
        background: #121212;
        display: flex;
        align-items: center;
        justify-content: center;

        .m-seventh-content-2 {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .m-eighth {
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      margin: 40px 0;

      .m-eighth-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
        margin-top: -20px;

        @media (min-width: 560px) {
          margin-top: -120px;
        }

        @media (max-width: 400px) {
          margin-top: 0;
        }

        .m-eighth-content-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 12px;
          margin-top: 40px;

          @media (min-width: 560px) {
            margin-top: 120px;
          }
        }

        .eighth-text {
          max-width: 200px;
          text-align: end;
          line-height: 1.6;
          @media (min-width: 560px) {
            max-width: 360px;
          }
        }

        .m-eighth-img {
          width: 40%;
          max-width: 240px;
          border-radius: 12px;

          @media (min-width: 560px) {
            width: 60%;
          }
        }
      }

      .m-eighth-content.center {
        align-items: center;

        .m-eighth-content-box {
          align-items: flex-start;

          .eighth-text {
            text-align: start;
          }
        }
      }

      .m-eighth-content.end {
        align-items: center;
      }
    }

    .m-ninth {
      box-sizing: border-box;
      width: 100%;
      height: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      position: relative;

      .marquee-content {
        width: 100vw;
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 88px;
        left: 0;
        animation: marquee 10s linear infinite;
      }

      .m-review {
        width: 100vw;
      }
    }


    .m-tenth {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      padding: 60px 20px;
      box-sizing: border-box;
      background: #1A1A1A;

      .m-tenth-title {
        width: 100vw;
        padding: 0 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }

      .m-tenth-title-1 {
        font-size: 32px;
        font-weight: 700;
      }

      .m-tenth-title-2 {
        background: linear-gradient(90deg, #FFF 0%, #999 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      .m-tenth-content {
        width: 100%;
        min-width: calc(100vw - 40px);
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 24px 0;
        box-sizing: border-box;
        padding: 0 20px;

        .m-landing-gender {
          width: 100%;
          background: rgba(255, 255, 255, 1);
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .m-landing-gender-option {
            width: 50%;
            text-align: center;
            font-size: 16px;
            padding: 10px 0;
            color: #131313;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              background-color: rgba(230, 83, 126, .3);
            }
          }

          .m-landing-gender-option.selected {
            background-color: var(--secondary);
            color: white;
            font-weight: bold;
            border-radius: 8px;
          }
        }

        .m-input-box {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .input-label {
            min-width: 60px;
            text-align: end;
            white-space: nowrap;
          }

          .m-input {
            width: 100% !important;
            min-width: calc(100vw - 220px);
            font-size: 16px;
            font-family: Pretendard, sans-serif;
            font-weight: 500;
            padding: 12px;
            border-radius: 8px;
            border: none;
            outline: none;
          }
        }

        .m-submit {
          width: 100%;
          padding: 12px 40px;
          border-radius: 20px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          background: var(--secondary);
          border: none;
          color: white;

          &:disabled {
            opacity: .6;
            cursor: default;
          }
        }

        .m-warning-text {
          width: 100%;
          color: rgba(255, 255, 255, .4);
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          cursor: pointer;

          .policy-text {
            font-size: 14px;
            text-align: start;
            font-weight: 400;
            line-height: 140%;
          }

          .checkbox {
            width: 20px;
            height: 20px;
            accent-color: var(--primary);
            cursor: pointer;
          }

          .a {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              color: var(--primary);
            }

          }
        }
      }
    }

    .download {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 48px;

      .download-text {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        opacity: .6;
      }

      .qr-code {
        width: 32%;
        max-width: 320px;
      }
    }
  }
}


.text-14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  word-break: keep-all;
}

.text-16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.text-20 {
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.text-24 {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.text-28 {
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
}

.text-32 {
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
}

.text-36 {
  font-size: 36px;
  font-weight: 700;
  line-height: 140%;
}

.m-title-1 {
  font-size: 4vw;
  font-weight: 700;
}

.m-title-2 {
  font-size: 5.6vw;
  font-weight: 700;
}

.m-title-3 {
  font-size: 6.4vw;
  font-weight: 800;
  margin-top: 12px;
}

.glowing {
  text-shadow: 0px 0px 6.8px rgba(255, 255, 255, 0.50);
}

.opacity-60 {
  color: rgba(255, 255, 255, 0.6);
  text-align: start;
}

.bold {
  font-weight: 700;
  color: #EDEDED;
}

.m-divider {
  width: 100%;
  min-height: 1px;
  height: 1px;
  background: linear-gradient(90deg, rgba(157, 157, 157, 0.20) 0%, rgba(255, 255, 255, 0.50) 19%, rgba(255, 255, 255, 0.50) 78.5%, rgba(157, 157, 157, 0.20) 100%);
}

.shortcut {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  height: 48px;
  max-height: 48px;
  min-height: 48px;
  background: var(--primary);
  z-index: 50;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
}

.shortcut.hidden {
  opacity: 0;
  pointer-events: none;
}