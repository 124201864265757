@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@keyframes marquee-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes mobile-marquee-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150vw);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0); /* 시작 위치 */
  }
  100% {
    transform: translateX(-100%); /* 한 세트만큼 왼쪽으로 이동 */
  }
}

.coming-soon {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #010101;

  .bg-top {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 30;
    gap: 120px;
    box-sizing: border-box;
    padding: 120px 0;

    @media (max-width: 768px) {
      padding: 80px 0;
    }

    .banner {
      width: 100%;
      max-width: 1200px;
      padding: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 80px;
      position: relative;
      overflow-x: hidden;

      @media (max-width: 768px) {
        padding: 20px;
        align-items: center;
      }

      .banner-title {
        width: 100%;
        color: #FFF;
        font-family: Gmarket Sans TTF, sans-serif;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        @media (max-width: 768px) {
          font-size: 40px;
          text-align: center;
        }
      }
    }

    .content-preview {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 80px;

      @media (max-width: 768px) {
        gap: 40px;
      }

      .content-preview-top {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .scroll-down {
          width: 72px;
          margin-top: 20px;

          @media (max-width: 768px) {
            width: 60px;
          }
        }
      }

      .content-frame {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow-x: hidden; /* 초과 콘텐츠 숨김 */
        position: relative;

        .content-slider {
          width: 100%;
          display: flex;
          gap: 20px;
          animation: scroll 16s linear infinite;
        }

        .content-img {
          width: 16%;
          border-radius: 12px;

          @media (max-width: 768px) {
            width: 28%;
          }
        }

        @media (max-width: 1024px) {
          gap: 12px;
        }
      }
    }

    .mid-frame {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    .process {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .navigate-btn {
        padding: 12px 24px;
        background: var(--primary);
        color: black;
        margin-top: 24px;
        border-radius: 12px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          opacity: 0.8;
        }
      }

      .navigate-info {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-top: 32px;

        .navigate-info-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;
          padding: 12px 24px;
          background: rgba(255, 255, 255, 0.12);
          border-radius: 12px;

          @media (max-width: 768px) {
            flex-direction: column;
            padding: 16px;
          }

          .navigate-info-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            @media (max-width: 768px) {
              align-items: center;
            }
          }
        }
      }
    }

    .step {
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: -20px;
      @media (max-width: 768px) {
        gap: 32px;
      }

      .step-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;

        @media (max-width: 768px) {
          width: 100vw;
          padding: 0 20px;
          box-sizing: border-box;
          flex-direction: column;
          gap: 12px;
        }

        .step-img {
          width: 40%;

          @media (max-width: 768px) {
            width: calc(100vw - 40px);
          }
        }

        .step-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          @media (max-width: 768px) {
            width: 100%;
          }

          .step-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            .step-num {
              width: 20px;
              height: 20px;
              border-radius: 10px;
              background: var(--primary);
              text-align: center;
              align-content: center;
              font-family: Gmarket Sans TTF, sans-serif;
              font-size: 14px;
              font-weight: 600;
              color: black;
            }

          }

          .step-desc {
            text-align: start;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.4;
          }

        }

        .step-content.center {
          align-items: flex-end;
          @media (max-width: 768px) {
          }

          .step-desc {
            text-align: end;
          }
        }
      }

      .step-row.reverse {
        @media (max-width: 768px) {
          flex-direction: column-reverse;
        }
      }
    }
  }
}


.text-xs {
  font-size: 14px;
  font-weight: 400;
  word-break: keep-all;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.text-sm {
  font-size: 20px;
  word-break: keep-all;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.text-lg {
  font-size: 36px;
  font-weight: 700;
  word-break: keep-all;
  line-height: 1.4;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}