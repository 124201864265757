.page-box {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 48px;
  background: #2C2C2C;
  position: relative;
  gap: 80px;
  margin-bottom: 24px;
  overflow: hidden;

  @media(max-width: 840px) {
    padding: 24px;
    gap: 40px;
  }


  .six-content {
    width: fit-content;
    max-width: 720px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  .six-content-img {
    width: 200px;
  }

  .six-content-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .text-frame {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .text-box {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .text-content {
        width: fit-content;
        padding: 0 20px;
        background: white;
        color: black;
        font-size: 14px;
        height: 32px;
        align-content: center;
        border-radius: 16px;
        white-space: nowrap;
        font-weight: 500;
      }

      .text-box.left {
        align-items: flex-start;
      }

      .text-box.right {
        align-items: flex-end;
      }
    }
  }

  .arrow {
    width: 20px;
    cursor: pointer;
  }

  .sixth-indicator {
    position: absolute;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}