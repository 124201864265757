.navigation {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  box-sizing: border-box;
  padding: 0 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(12px);

  .logo {
    width: 200px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 120px;
    }
  }

  .navigation-menus {
    display: flex;
    flex-direction: row;
    gap: 32px;

    .navigation-menu {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}