@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.criteria {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #010101;

  .criteria-bg-top {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .criteria-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 30;
    gap: 120px;
    box-sizing: border-box;
    padding: 120px 0;
  }

  .criteria-banner {
    width: 100%;
    max-width: 1200px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    position: relative;
    overflow-x: hidden;

    @media (max-width: 768px) {
      padding: 20px;
      align-items: center;
    }

    .criteria-banner-title {
      width: 100%;
      color: #FFF;
      font-family: Gmarket Sans TTF, sans-serif;
      font-size: 60px;
      line-height: 1.2;
      font-style: normal;
      font-weight: 700;
      text-align: start;

      @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
      }
    }

    .criteria-banner-bottom {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      overflow-x: hidden;

      @media (max-width: 768px) {
        width: 100vw;
        align-items: center;
      }
    }

    .criteria-marquee-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 24px;
      animation: marquee-scroll 12s linear infinite;

      @media (max-width: 768px) {
        gap: 12px;
        animation: marquee-scroll 12s linear infinite;
      }
    }

    .criteria-item {
      color: white;
      font-size: 1.2rem;
      white-space: nowrap;
      border-radius: 20px;
      background: rgba(255, 160, 0, 0.12);
      backdrop-filter: blur(10px);
      padding: 8px 20px;
      box-sizing: border-box;

      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
}

.criteria-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;

  .criteria-scroll-icon {
    width: 72px;
    margin-top: 20px;

    @media (max-width: 768px) {
      width: 60px;
    }
  }
}

.criteria-top {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  max-width: 900px;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 6fr);
    padding: 0 24px;
    box-sizing: border-box;
  }
}

.text-sm {
  font-size: 20px;
  word-break: keep-all;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.criteria-component {
  box-sizing: border-box;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 12px 0 12px;
  border-radius: 20px;

  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 8px 8px 0 8px;
  }

  .criteria-boolean {
    font-size: 14px;
    color: #777777;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .criteria-component-verified {
    width: 100%;
  }
}

.criteria-bottom {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 800px;
  gap: 32px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 2fr);
    gap: 20px;
    margin-top: -32px;
  }
}

.row-criteria {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 12px;
  position: relative;
  border-radius: 12px;
  gap: 8px;
  margin-top: -60px;

  @media (max-width: 768px) {
    margin-top: 0;
  }

  .criteria-component-sig {
    position: absolute;
    top: -32px;
    right: -32px;
    width: 80px;
  }
}

.criteria-component-title {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 8px;
  margin-bottom: 4px;

  .criteria-component-title-icon {
    width: 20px;
    height: 20px;
  }

  .criteria-component-title-text {
    font-size: 16px;
    color: #353535;
    font-weight: 600;
  }
}

.criteria-component-content {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);

  @media (max-width: 768px) {
    font-size: 11px;
  }
}

.criteria-divider {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  .criteria-row {
    width: 40%;
    height: 2px;
    min-height: 2px;
    max-height: 2px;
    background: #2D2D2D;
  }

  .criteria-divider-title {
    background: linear-gradient(91deg, #4D4D4D 0.25%, #494949 34.16%, #AFAFAF 99.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    white-space: nowrap;
    font-weight: 600;
    padding: 0 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
}