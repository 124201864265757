.admin {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 24px;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;


  .admin-login-content {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }


}

.admin-auth {
  box-sizing: border-box;
  padding: 20px;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  .list {
    width: 100%;
    max-width:1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 32px;
    font-weight: 700;
  }
}

.admin-button {
  width: 320px;
  height: 48px;
  background: rgba(0, 0, 0, 0.08);
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  font-family: Pretendard, sans-serif;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }
}