.marquee-banner-bottom {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 100vw;
    align-items: center;
  }


  .date {
    padding: 12px 40px;
    border: 2px solid white;
    border-radius: 24px;
    font-size: 20px;
    font-family: Gmarket Sans TTF, sans-serif;
    -webkit-text-stroke: 0.2px white;
    opacity: .8;

    @media (max-width: 768px) {
      font-size: 14px;
      padding: 8px 24px;
    }
  }

  .marquee-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
    animation: marquee-scroll 12s linear infinite;

    @media (max-width: 768px) {
      gap: 12px;
      animation: marquee-scroll 12s linear infinite;
    }
  }

  .marquee-item {
    flex: 0 0 auto;
    color: white;
    font-size: 1.2rem;
    white-space: nowrap;
    border-radius: 20px;
    background: rgba(255, 160, 0, 0.12);
    backdrop-filter: blur(10px);
    padding: 8px 20px;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}