.card-container {
  max-width: 100%;
  width: 900px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .card-content-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 12px;
    }

    .card-content-bottom {
      background-color: #43464A;
      padding: 20px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .card-content-box {
      box-sizing: border-box;
      width: 32%;
      min-width: 248px;
      aspect-ratio: 1;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 12px;
      border-radius: 12px;
    }

    .image-1 {
      background-image: url('../assets/bg-image-1.png');
    }

    .image-2 {
      background-image: url('../assets/bg-image-2.png');
    }

    .image-3 {
      background-image: url('../assets/bg-image-3.png');
    }
  }
}

.card-desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.card-mid {
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.text-24 {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.card-subtitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
}

.card-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
}
