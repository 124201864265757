.scroll {
  width: 100vw;
  max-width: 100vw;
  overflow-x: scroll;
  padding: 20px;
  box-sizing: border-box;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.m-third-content {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 744px;

  .m-diff-box {
    width: 240px;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid var(--secondary);
    border-radius: 32px;
    gap: 20px;

    .m-diff-box-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }

    .m-diff-box-btn {
      width: 100%;
      height: 36px;
      padding: 0 12px;
      box-sizing: border-box;
      background: var(--secondary);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
      border-radius: 18px;
      font-size: 14px;
      font-weight: 600;

      .m-diff-box-icon {
        width: 20px;
      }
    }
  }

  .m-diff-box.last {
    justify-content: flex-start;
    gap: 12px;
  }

  .diff-box-linear {
    background: linear-gradient(180deg, rgba(149, 59, 245, 0.15) 0%, rgba(87, 35, 143, 0.15) 100%);
  }
}